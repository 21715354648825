import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = ({ src, alt, caption, className }) => (
  <figure className={className}>
    <GatsbyImage image={getImage(src)} alt={alt} />
    {caption && <figcaption>{caption}</figcaption>}
  </figure>
);

export default Image;
