import * as React from "react";
import { IntlProvider } from "react-intl";
import { MDXProvider } from "@mdx-js/react";
import MdxLink from "./mdx-link";
import Accordion from "./accordion";
import ButtonLink from "./button-link";
import Grading from "./grading";
import Image from "./image";
import KeyFigure from "./key-figure";
import Product from "./product";
import Section from "./section";
import en from "../locales/en.json";
import fr from "../locales/fr.json";

const messages = { en, fr };

const components = {
  a: MdxLink,
  Accordion,
  ButtonLink,
  Grading,
  Image,
  KeyFigure,
  Product,
  Section,
};

const Wrapper = ({ children, pageContext: { locale } }) => (
  <IntlProvider locale={locale} defaultLocale="en" messages={messages[locale]}>
    <MDXProvider components={components}>{children}</MDXProvider>
  </IntlProvider>
);

export default Wrapper;
