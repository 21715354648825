import * as React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useIntl } from "react-intl";

const LocalizedLink = ({ to, animated, ...props }) => {
  const { locale } = useIntl();
  const path = `/${locale}${to}`;

  return (
    <AniLink
      {...props}
      to={path}
      {...(animated !== false && {
        cover: true,
        direction: `right`,
        duration: 1,
        bg: `#cdd4ba`,
      })}
    />
  );
};

export default LocalizedLink;
