import * as React from "react";
import { FormattedMessage } from "react-intl";

const levels = {
  1: <FormattedMessage id="moderate" />,
  2: <FormattedMessage id="high" />,
  3: <FormattedMessage id="veryHigh" />,
};

const Grading = ({ grading: { impact, difficulty }, isHeaderGrading }) => (
  <table className="grading">
    <thead>
      <tr>
        <th>
          <FormattedMessage id="impact" />
        </th>
        <th>
          <FormattedMessage id="difficulty" />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {!isHeaderGrading && (
            <span>
              <FormattedMessage id="impact" />
            </span>
          )}
          <svg
            width="80"
            height="24"
            viewBox="0 0 80 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M12 18L6.12215 21.0902L7.24472 14.5451L2.48943 9.90983L9.06107 8.95492L12 3L14.9389 8.95492L21.5106 9.90983L16.7553 14.5451L17.8779 21.0902L12 18Z"
              fill="currentColor"
              fillOpacity={impact.level >= 1 ? null : 0.3}
            />
            <path
              d="M40 18L34.1221 21.0902L35.2447 14.5451L30.4894 9.90983L37.0611 8.95492L40 3L42.9389 8.95492L49.5106 9.90983L44.7553 14.5451L45.8779 21.0902L40 18Z"
              fill="currentColor"
              fillOpacity={impact.level >= 2 ? null : 0.3}
            />
            <path
              d="M68 18L62.1221 21.0902L63.2447 14.5451L58.4894 9.90983L65.0611 8.95492L68 3L70.9389 8.95492L77.5106 9.90983L72.7553 14.5451L73.8779 21.0902L68 18Z"
              fill="currentColor"
              fillOpacity={impact.level === 3 ? null : 0.3}
            />
          </svg>
          <span>{levels[impact.level]}</span>
          {!isHeaderGrading && <p>{impact.description}</p>}
        </td>
        <td>
          {!isHeaderGrading && (
            <span>
              <FormattedMessage id="difficulty" />
            </span>
          )}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M3 13.5C3 12.6716 3.67157 12 4.5 12C5.32843 12 6 12.6716 6 13.5V19.5C6 20.3284 5.32843 21 4.5 21C3.67157 21 3 20.3284 3 19.5V13.5Z"
              fill="currentColor"
              fillOpacity={difficulty.level >= 1 ? null : 0.3}
            />
            <path
              d="M10 9C10 8.17157 10.6716 7.5 11.5 7.5C12.3284 7.5 13 8.17157 13 9V19.5C13 20.3284 12.3284 21 11.5 21C10.6716 21 10 20.3284 10 19.5V9Z"
              fill="currentColor"
              fillOpacity={difficulty.level >= 2 ? null : 0.3}
            />
            <path
              d="M17 4.5C17 3.67157 17.6716 3 18.5 3C19.3284 3 20 3.67157 20 4.5V19.5C20 20.3284 19.3284 21 18.5 21C17.6716 21 17 20.3284 17 19.5V4.5Z"
              fill="currentColor"
              fillOpacity={difficulty.level >= 3 ? null : 0.3}
            />
          </svg>
          <span>{levels[difficulty.level]}</span>
          {!isHeaderGrading && <p>{difficulty.description}</p>}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Grading;
