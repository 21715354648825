import * as React from "react";
import LocalizedLink from "./localized-link";

const MdxLink = ({ href, children, ...props }) => {
  if (href.startsWith(`http`) || href.includes(`.`)) {
    return (
      <a {...props} href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return (
    <LocalizedLink to={href} {...props}>
      {children}
    </LocalizedLink>
  );
};

export default MdxLink;
