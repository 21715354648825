import * as React from "react";
import slugify from "slugify";
import Icon from "./icon";

const Accordion = ({ title, icon, className, children }) => {
  const [accordion, setAccordion] = React.useState(null);
  const accordionEl = React.useRef(null);
  const accordionId = slugify(title, { lower: true, strict: true });
  const delay = accordion ? 10 : 200;

  const handleClick = () => {
    setAccordion(!accordion ? true : null);
    accordionEl.current.style = `--height: ${accordionEl.current.scrollHeight}px`;

    setTimeout(() => {
      accordionEl.current.removeAttribute(`style`);
    }, delay);
  };

  return (
    <div className={`accordion${className ? ` ${className}` : ``}`}>
      <button
        onClick={handleClick}
        aria-expanded={accordion || false}
        aria-controls={accordionId}
      >
        {icon && <Icon name={icon} />}
        <span>{title}</span>
        <Icon name="arrow" />
      </button>
      <div
        ref={accordionEl}
        className="accordion-content"
        id={accordionId}
        aria-label={title}
        aria-hidden={!accordion || null}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
