import * as React from "react";
import MdxLink from "./mdx-link";

const ButtonLink = ({ ...props }) => (
  <MdxLink
    {...props}
    className={`button${props.className ? ` ${props.className}` : ``}`}
  />
);

export default ButtonLink;
