import * as React from "react";
import slugify from "slugify";
import Icon from "./icon";

const Product = ({ title, icon, children }) => {
  const [overlay, setOverlay] = React.useState(null);
  const overlayId = slugify(title, { lower: true, strict: true });
  const handleClick = () => setOverlay(!overlay ? true : null);

  React.useEffect(() => {
    const handleKeyDown = (event) => event.key === `Escape` && setOverlay(null);
    window.addEventListener(`keydown`, handleKeyDown);
    return () => window.removeEventListener(`keydown`, handleKeyDown);
  }, []);

  return (
    <div className="product">
      <div className="product-button">
        <button onClick={handleClick} aria-controls={overlayId}>
          {title}
        </button>
        <figure aria-hidden="true">
          <Icon name={icon} />
        </figure>
      </div>
      <div
        id={overlayId}
        className="product-overlay"
        aria-modal={overlay}
        tabIndex={-1}
        role={overlay ? `dialog` : null}
        aria-label={title}
      >
        <div className="product-content">
          <button onClick={handleClick}>
            <Icon name="close" />
          </button>
          <p>
            <Icon name={icon} />
            <span>{title}</span>
          </p>
          <div>{children}</div>
        </div>
        <div
          className="product-backdrop"
          onClick={handleClick}
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

export default Product;
