import * as React from "react";
import Icon from "./icon";

const KeyFigure = ({ figure, text, icon, className }) => (
  <p className={`key-figure${className ? ` ${className}` : ``}`}>
    {icon && <Icon name={icon} />}
    <strong>{figure}</strong>
    <span>{text}</span>
  </p>
);

export default KeyFigure;
